/* @flow */

import * as React from 'react'
import { ContactFormTemplate } from '../templates'

type Props = {
  location: {
    href: string,
  },
}

export default function Form(props: Props) {
  const { location } = props

  return (
    <ContactFormTemplate
      url={location.href}
      title="Nous contacter"
      name="Wino Contact"
      description="Des questions concernant le logiciel de caisse Wino ?"
    />
  )
}
